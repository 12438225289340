import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TestResultModel } from '@models/test-result-model';
import { UserCertificationTestModel } from '@models/user-certification-test-model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { date } from './date.service';
import { TestModel } from '@models/test-model';
import { UpdateUserTestModel } from '@models/update-user-test-model';

@Injectable({
  providedIn: 'root'
})
export class UserTestService {
  private apiUrl: string;

  public get url(): string {
    return this.apiUrl;
  }

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.apiUrl = `${this.baseUrl}api/user/tests`;
  }

  getTestResults(userTestId: string) {
    return this.http.get<TestResultModel>(`${this.apiUrl}/${userTestId}/results`);
  }

  approveTest(userTestId: string, score: number) {
    return this.http
      .patch<UserCertificationTestModel>(`${this.apiUrl}/${userTestId}/score`, { score: score })
      .pipe(tap(userTest => date(userTest, 'scheduledStartTime', 'expiryDate')));
  }

  getMedia(userBlockId: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/media/${userBlockId}`, {
      responseType: 'blob'
    });
  }

  scheduleTestStartTime(userTest: UserCertificationTestModel, testStartDateTime: Date) {
    return this.http
      .patch<UserCertificationTestModel>(`${this.apiUrl}/${userTest.id}/schedule`, { testStartDateTime })
      .pipe(tap(userTest => date(userTest, 'scheduledStartTime', 'expiryDate')));
  }

  getSampleTests() {
    return this.http.get<TestModel[]>(`${this.apiUrl}/sampleTests`);
  }

  resetTest(userTestId: string) {
    return this.http
      .delete<UserCertificationTestModel>(`${this.apiUrl}/${userTestId}`)
      .pipe(tap(data => date(data, 'expiryDate', 'startDate', 'endDate', 'scheduledStartTime')));
  }

  updateUserTestLimits(userTestId: string, params: UpdateUserTestModel) {
    return this.http
      .patch<UserCertificationTestModel>(`${this.apiUrl}/${userTestId}/limits`, { ...params })
      .pipe(tap(data => date(data, 'expiryDate', 'startDate', 'endDate', 'scheduledStartTime')));
  }
}
