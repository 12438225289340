import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CommunicationSearchResultModel } from '@models/communication-search-result-model';
import { CommunicationSearchOptions } from '@models/communication-search-options';
import { CommunicationModel } from '@models/communication-model';
import { distinct, map, tap } from 'rxjs/operators';
import { date } from './date.service';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material';
import { CommunicationPopupComponent } from '@components/communication-popup/communication-popup.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiUrl = `${this.baseUrl}api/communications`;
  }

  getCommunication(id: string) {
    if (id === 'new') {
      return of({} as CommunicationModel);
    }

    return this.http.get<CommunicationModel>(`${this.apiUrl}/${id}`);
  }

  /**
   * Get the currently un-read communications for a user.
   */
  getUnreadCommunications() {
    return this.http.get<CommunicationModel[]>(`${this.apiUrl}/unread`).pipe(
      tap(comms => date(comms, 'publishDate', 'expiryDate')),
      tap(comms => comms.sort(this.sortCommunicationsDescending))
    );
  }

  private sortCommunicationsDescending(a, b) {
    return a.publishDate.getTime() > b.publishDate.getTime() ? -1 : 1;
  }

  /**
   * Looks up the unread communications/announcements for the logged in user and
   * displays them in popup dialogs.
   */
  showUnreadCommunications() {
    this.getUnreadCommunications()
      .pipe(distinct())
      .subscribe(communications => {
        communications.forEach(comm => {
          this.showCommunications(comm);
        });
      });
  }

  private showCommunications(communication: CommunicationModel) {
    const ref = this.dialog.open(CommunicationPopupComponent, {
      minWidth: '500px',
      minHeight: '400px',
      maxWidth: '80vw',
      maxHeight: '80vw',
      width: '', // clear the width.. something else is setting it
      data: {
        communication
      }
    });

    ref.afterClosed().subscribe(data => {
      this.markCommunicationRead(communication).subscribe();
    });
  }

  /**
   * Get all the communications available, sorted by the publish date in descending order.
   */
  getCommunications() {
    return this.http.get<CommunicationModel[]>(`${this.apiUrl}`).pipe(
      tap(comms => date(comms, 'publishDate', 'expiryDate')),
      tap(comms => comms.sort(this.sortCommunicationsDescending))
    );
  }

  putCommunication(id: string, communicationModel: CommunicationModel) {
    return this.http.put(`${this.apiUrl}/${id}`, communicationModel);
  }

  markCommunicationRead(comm: CommunicationModel) {
    return this.http.put(`${this.apiUrl}/markRead`, comm);
  }

  searchCommunications(
    options: CommunicationSearchOptions
  ): Observable<CommunicationSearchResultModel> {
    const params = {};
    Object.keys(options)
      .filter(x => options[x] !== null)
      .forEach(x => {
        params[x] = options[x].toString();
      });
    return this.http.get<CommunicationSearchResultModel>(
      `${this.apiUrl}/search`,
      { params }
    );
  }

  deleteCommunication(communicationID) {
    return this.http.delete(`${this.apiUrl}/${communicationID}`);
  }

  copyCommunication(communicationID) {
    return this.http.get(`${this.apiUrl}/copy/${communicationID}`);
  }

  setCommunication(communicationModel: CommunicationModel) {
    if (communicationModel.id) {
      // Update existing
      return this.http
        .put(`${this.apiUrl}/${communicationModel.id}`, communicationModel)
        .pipe(
          map(_ => {
            return { ...communicationModel };
          })
        );
    } else {
      // Create new
      return this.http
        .post<CommunicationModel>(`${this.apiUrl}`, communicationModel)
        .pipe(
          tap(communication => date(communication, 'publishDate', 'expiryDate'))
        );
    }
  }
}
