import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PurchasableCertificationModel } from '@models/purchasable-certification-model';
import { UserCertificationModel } from '@models/user-certification-model';
import { date } from './date.service';
import { tap } from 'rxjs/operators';
import { TestCertificationAdminModel } from '@models/test-certification-admin-model';

@Injectable({
  providedIn: 'root'
})
export class UserCertificationService {
  private apiUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.apiUrl = `${this.baseUrl}api/user/certifications`;
  }

  getUserCertification(
    userCertificationId: string,
    withEmails = false,
    withSkillAreas = false,
    withAttachments = false
  ) {
    const params = {
      withEmails: withEmails.toString(),
      withSkillAreas: withSkillAreas.toString(),
      withAttachments: withAttachments.toString()
    };
    const result = this.http
      .get<UserCertificationModel>(`${this.apiUrl}/${userCertificationId}`, {
        params
      })
      .pipe(tap(cert => date(cert, 'tests', 'startDate', 'endDate', 'expiryDate', 'scheduledStartTime', 'assignedUsers', 'expiryDate')));
    return result;
  }

  putUserCertification(userCertificationId: string, userCertificationModel: UserCertificationModel) {
    return this.http.put(`${this.apiUrl}/${userCertificationId}`, userCertificationModel);
  }

  updateRegistrationProgress(userCertificationId: string, tabName: string) {
    return this.http.patch(
      `${this.apiUrl}/${userCertificationId}/updateRegistrationProgress?progress=${tabName}`,

      null
    );
  }

  // Admin set purchased flag.
  completeCertificationPurchase(userCertificationId: string, isPurchased: boolean) {
    return this.http.patch<UserCertificationModel>(`${this.apiUrl}/${userCertificationId}/purchase`, {
      isPurchased: isPurchased
    } as TestCertificationAdminModel);
  }

  approveCertification(userCertificationId: string, data: TestCertificationAdminModel) {
    return this.http.patch<UserCertificationModel>(`${this.apiUrl}/${userCertificationId}/score`, data);
  }

  postUserCertification(userCertificationModel: UserCertificationModel) {
    return this.http
      .post<UserCertificationModel>(this.apiUrl, userCertificationModel)
      .pipe(tap(cert => date(cert, 'expiryDate')));
  }

  getCerts(withEmails = false, withSkillAreas = false, withAttachments = false) {
    const params = {
      withEmails: withEmails.toString(),
      withSkillAreas: withSkillAreas.toString(),
      withAttachments: withAttachments.toString()
    };
    return this.http
      .get<UserCertificationModel[]>(`${this.apiUrl}`, { params })
      .pipe(
        tap(certs => date(certs, 'tests', 'expiryDate', 'completedDate', 'startDate', 'endDate', 'scheduledStartTime'))
      );
  }

  getPurchasable() {
    return this.http.get<PurchasableCertificationModel[]>(`${this.apiUrl}/purchasable`);
  }

  getPurchasableById(userCertification: UserCertificationModel) {
    return this.http.get<PurchasableCertificationModel>(
      `${this.apiUrl}/purchasable/${userCertification.certificationId}`
    );
  }
}
